<template>
  <div
    class="container"
    :style="{
      'border': !image ? '1px dashed #a3a8b1' : '1px solid #a3a8b1'
    }"
    @dragover.prevent="dragOver"
    @dragleave.prevent="dragLeave"
    @drop.prevent="drop($event)"
  >
    <div
      v-show="dropped == 2"
      class="drop"
    />
    <!-- Error Message -->
    <div
      v-show="error"
      class="error"
    >
      {{ error }}
    </div>

    <!-- To inform user how to upload image -->
    <div
      v-show="!image"
      class="beforeUpload"
    >
      <input
        ref="uploadInput"
        type="file"
        style="z-index: 1"
        accept="image/*"
        @change="previewImg"
      >
      <p class="mainMessage">
        Drag and Drop file here or <span class="text-primary">Browse for file</span>
      </p>
    </div>
    <div
      v-show="image"
      class="imgsPreview"
    >
      <div
        class="imageHolder"
      >
        <img :src="image">
        <feather-icon
          icon="Trash2Icon"
          size="20"
          class="delete text-primary"
          @click="deleteImg"
        />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'UploadSingleImage',
  props: {
    image: {
      type: [String, File],
      default: null,
    },
    fileError: {
      type: String,
      default: 'Unsupported file type',
    },
  },
  data() {
    return {
      error: '',
      files: [],
      dropped: 0,
    }
  },
  methods: {
    dragOver() {
      this.dropped = 2
    },
    dragLeave() {
      this.dropped = 0
    },
    drop(e) {
      let status = true
      const files = Array.from(e.dataTransfer.files)
      if (e && files) {
        files.forEach(file => {
          if (file.type.startsWith('image') === false) status = false
        })
        if (status === true) {
          this.files.push(...files)
          this.previewImg()
        } else {
          this.error = this.$props.fileError
        }
      }
      this.dropped = 0
    },
    readAsDataURL(file) {
      return new Promise(((resolve, reject) => {
        const fr = new FileReader()
        // eslint-disable-next-line func-names
        fr.onload = function () {
          resolve(fr.result)
        }
        // eslint-disable-next-line func-names
        fr.onerror = function () {
          reject(fr)
        }
        fr.readAsDataURL(file)
      }))
    },
    deleteImg() {
      this.files = []
      this.$emit('changed', null)
      this.$refs.uploadInput.value = null
    },
    previewImg(event) {
      if (this.dropped === 0) this.files.push(...event.currentTarget.files)
      this.error = ''
      const readers = []
      if (!this.files.length) return
      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < this.files.length; i++) {
        readers.push(this.readAsDataURL(this.files[i]))
      }
      Promise.all(readers).then(values => {
        this.$emit('changed', values[0])
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.container {
  width: 100%;
  height: 100%;
  border-radius: 10px;
  padding: 1rem;
  position: relative;
}
.drop {
  width: 100%;
  height: 100%;
  top: 0;
  border-radius: 10px;
  position: absolute;
  background-color: #f4f6ff;
  left: 0;
  border: 1px dashed #a3a8b1;
}
.error {
  text-align: center;
  color: red;
  font-size: 15px;
}
.beforeUpload {
  position: relative;
  text-align: center;
  input {
    width: 100%;
    margin: auto;
    height: 100%;
    opacity: 0;
    position: absolute;
    background: red;
    display: block;
    &:hover {
      cursor: pointer;
    }
  }
}
.mainMessage {
  margin-bottom: 0;
}
.imgsPreview {
  .imageHolder{
    display: flex;
    justify-content: space-between;
    align-items: center;
    img {
      max-width: 90%;
    }
    .delete {
      cursor: pointer;
    }
  }
}
</style>
