<template>
  <b-modal
    id="save-instance-modal"
    ref="save-instance-modal"
    centered
    title="Instance adding information"
    cancel-variant="outline-secondary"
    no-close-on-backdrop
    body-class="py-2 text-center"
    footer-class="d-flex justify-content-between"
    :hide-footer="hideFooter"
  >
    <div>
      <b-spinner
        v-if="isLoading"
        style="width: 70px; height: 70px;"
        label="Large Spinner"
        variant="primary"
      />
      <feather-icon
        v-else
        :icon="modalData.icon"
        :class="`text-${modalData.iconVariant}`"
        size="70"
      />
      <h2 class="py-2">
        {{ modalData.title }}
      </h2>
      <p>{{ modalData.subtitle }}</p>
    </div>
    <template #modal-footer>
      <b-button
        variant="outline-secondary"
        @click="$router.push({name: 'admin-instances-list'})"
      >
        No
      </b-button>
      <b-button
        variant="primary"
        @click="$router.push({name: 'admin-users'})"
      >
        Yes
      </b-button>
    </template>
  </b-modal>
</template>

<script>

import { mapGetters } from 'vuex'
import {
  BButton,
  BSpinner,
} from 'bootstrap-vue'

export default {
  components: {
    BButton,
    BSpinner,
  },
  computed: {
    ...mapGetters({
      isLoading: 'app-instances/getIsLoading',
      modalData: 'app-instances/getSaveInstanceModalData',
    }),
    hideFooter() {
      return this.isLoading || !this.modalData.showFooter
    },
  },
  methods: {
    closeModal() {
      this.$bvModal.hide('save-instance-modal')
    },
  },
}
</script>

<style>

</style>
